var jq = jQuery.noConflict();

jq(document).ready(function(){

    setGlobalVars();

    identifyDevice();

    treatNavbar();

    handleErrorMsgs();

    slider();

    coverEffects();

    scrollToElement();

    stepsBlock();

    cookieNotice();

    jq(function() {
        FastClick.attach(document.body);
    });

});

jq(window).resize(function(){
    setGlobalVars();
    identifyDevice();
    stepsBlock();
});


function handleErrorMsgs(){

}

function setGlobalVars(){
    var getUrl = window.location;
    var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
    baseDir = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];

    viewportWidth = jq(window).width();
    viewportHeight = jq(window).height();

    documentHeight = jq(document).height();

    isMobileCheck = false;
};

function identifyDevice() {
    if(viewportWidth<768) {
        jq("body").addClass("mobile");
        jq("body").removeClass("tablet");
        jq("body").removeClass("desktop");
        isMobileCheck = true;
    }else if(767<viewportWidth && viewportWidth<992){
        jq("body").addClass("tablet");
        jq("body").removeClass("desktop");
    }else {
        jq("body").addClass("desktop");
        jq("body").removeClass("mobile");
        jq("body").removeClass("tablet");
    }
};

function treatNavbar() {

    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menu'),
        'padding': 256,
        'tolerance': 70,
        'side': 'right'
    });
    document.querySelector('.menu-link').addEventListener('click', function() {
        slideout.toggle();
        jq(".navbar-toggle.menu-link").toggleClass("active");
    });


    jq(window).scroll(function () {
        if(!isMobileCheck){
            if (jq(this).scrollTop() > jq(".topBar").height()) {
                if(!jq('header.fixed').length){
                    jq('header').addClass("fixed");
                    jq('.topBar').addClass("fixed");
                }
            } else {
                jq('header.fixed').removeClass("fixed");
                jq('.topBar.fixed').removeClass("fixed");
            }
        }else {
            jq('header').addClass("fixed");
        }
    });

};


function slider(){
    if(jq(".reviewsCarousel").length){
	    jq(".reviewsCarousel").slick({
	        dots: true,
	        arrows: true,
	        speed: 1500,
	        autoplay: true,
            autoplaySpeed: 4000,
	        slidesToShow: 1,
	        slidesToScroll: 1
	    });
    }
}

function coverEffects() {

	if(jq(".homepageCover").length){
		
	}

}

function stepsBlock(){  
    if(jq(".sectionBlock.steps").length){
        var heightest = 0;
        jq(".sectionBlock.steps .content").each(function(){
            if(jq(this).height()>heightest){
                heightest = jq(this).height();
            }
        });
        jq(".sectionBlock.steps .content").height(heightest);
    }
}

function scrollToElement() {
    var hash = window.location.hash;
    if(hash.length != 0) {
        if(jq(hash).length){
            jq('html, body').animate({ scrollTop: (jq(hash).offset().top-50) });
        }
    }
    jq(".scrollTo").click(function(){
        hash = jq(this).attr("href");
        jq('html, body').animate({ scrollTop: (jq(hash).offset().top-50) });
    });
}

function cookieNotice() {
    console.log('test');
    if(jq(".cookie-notice").length){
        if(!localStorage.getItem('cookie-policy')){
            jq(".cookie-notice").show();
        };
        jq(".cookie-notice .accept").click(function(){
            localStorage.setItem('cookie-policy', true);
            var cookiePolicy = localStorage.getItem('cookie-policy');
            jq(".cookie-notice").hide();
        });
        jq(".cookie-notice .dont-accept").click(function(){
            localStorage.clear();
            jq(".cookie-notice").hide();
            //jq('#cookieModal').modal({backdrop: 'static', keyboard: false});
            var cookiePolicy = localStorage.getItem('cookie-policy');
        });
        jq('.cookie-notice .dont-accept').magnificPopup({
            type:'inline',
            midClick: true,
            fixedContentPos: true,
            modal: true,
            src: '#cookieModal',

        });
        jq("#cookieModal .accept-and-reload").click(function(){
            localStorage.setItem('cookie-policy', true);
            location.reload();
        });
        
    }
}